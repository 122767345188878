import Games from '@/views/Games'
import GamesDetail from '@/views/Games/Detail'

const routes = [
  {
    path: '/games',
    name: 'Games',
    component: Games,
  },
  {
    path: '/games/detail',
    name: 'GamesDetail',
    component: GamesDetail,
  },
]

export default routes
