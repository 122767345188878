export const STAKING = {
  fbx90_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 90,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 6000000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/07/07 12:00:00'),
    tag: 'new',
    canEarly: false,
    AdditionalReward: {
      template: 'DeFi.Get_A1_TowerForEvery_A2_FBXStaked',
      params: {
        name: 'Missile',
        price: '1.2M',
      },
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0x369204CE8A07287A384020f8CAd32456b9f9fdbF',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
  fbx180_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 180,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 10000000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/07/07 12:00:00'),
    tag: 'new',
    canEarly: true,
    AdditionalReward: {
      template: 'DeFi.Get_A1_TowerForEvery_A2_FBXStaked',
      params: {
        name: 'Missile',
        price: '600k',
      },
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0x9f7D4e64b7a9F6fa16E3a8B50340776979E6f21A',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
  fbx360_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 360,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 4000000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/07/07 12:00:00'),
    tag: 'new',
    canEarly: true,
    AdditionalReward: {
      template: 'DeFi.Get_A1_TowerForEvery_A2_FBXStaked',
      params: {
        name: 'Missile',
        price: '300k',
      },
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0xF0be62E751942cC59d91eA98aFe9128C1Cd9D0Bf',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
  fbx720_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 720,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 3000000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/07/07 12:00:00'),
    tag: 'new',
    canEarly: true,
    AdditionalReward: {
      template: 'DeFi.Get_A1_TowerForEvery_A2_FBXStaked',
      params: {
        name: 'Missile',
        price: '150k',
      },
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0xa8e30d44b90b549290bc7346136b65f7442F75c2',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
  fbx1080_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 1080,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 2000000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/07/07 12:00:00'),
    tag: 'new',
    canEarly: true,
    AdditionalReward: {
      template: 'DeFi.Get_A1_TowerForEvery_A2_FBXStaked',
      params: {
        name: 'Missile',
        price: '100k',
      },
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0xc5876Dd8F73367E4526E933FC800c7b08f8D10A6',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
  fbx90: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 90,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 3000000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/06/07 12:00:00'),
    canEarly: false,
    AdditionalReward: {
      template: '',
      params: {},
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0x4D0bBd6a237Ce66722fa3B713735fD747FF47D10',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
  fbx180: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 180,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 5000000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/06/07 12:00:00'),
    canEarly: true,
    AdditionalReward: {
      template: '',
      params: {},
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0x884023c473ECac15edEa76306A85757817bacd3A',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
  fbx360: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 360,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 2000000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/06/07 12:00:00'),
    canEarly: true,
    AdditionalReward: {
      template: 'DeFi.Laser_Tower_100k_FBX',
      params: {},
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0xB18F065FC971e0D31A5945BCAfB623Ff6a535Cd1',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
  fbx720: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 720,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 1500000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/06/07 12:00:00'),
    canEarly: true,
    AdditionalReward: {
      template: 'DeFi.Railgun_Tower_100k_FBX',
      params: {},
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0xA04d2dc966047DC2334AE679B61E2882e3f4F865',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
  fbx1080: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'FBX',
    duration: 1080,
    amountStr: '≥10000.00',
    amount: 10000.0,
    totalNum: 1000000.0,
    visible: true,
    isEnd: Date.now() >= Date.parse('2022/06/07 12:00:00'),
    canEarly: true,
    AdditionalReward: {
      template: 'DeFi.Missile_Tower_100k_FBX',
      params: {},
      text: '',
    },
    token: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    defi: '0xb95c84A14f1A0d34dcEc30645339d5902b46161d',
    texts: [
      'DeFi.Single_Text1',
      'DeFi.Single_Text2',
      'DeFi.Single_Text3',
      'DeFi.Single_Text4',
      'DeFi.Single_Text5',
    ],
  },
}
