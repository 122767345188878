<template>
  <Page class="games">
    <div class="games-header">
      <img src="@/assets/page-games/header-logo.png" />
      <div class="dropdown-wrap">
        <div class="header-show" v-show="!dropdownShow" @click="showType()">
          {{ showGameType }}
        </div>
        <div class="header-dropdown" v-show="dropdownShow">
          <div v-for="(type, index) in gameType" :key="index" @click="selectType(index)">
            {{ type }}
          </div>
        </div>
      </div>
    </div>
    <div class="games-wrap">
      <div class="banners">
        <Slider
          class="banner-slider"
          ref="bannerSlider"
          :autoplay="true"
          :distance="8000"
          @slide-start="onBannerSlide"
        >
          <SliderItem>
            <router-link
              :to="{
                path: '/games/detail',
                query: { id: 'ham-fantasy' },
              }"
            >
              <img
                class="banner m-v-hide"
                src="https://static.forthbox.io/image/gamecenter/ham-fantasy.jpg"
              />
            </router-link>
          </SliderItem>
        </Slider>
      </div>
      <div class="games-sub-header">
        <div
          v-for="(name, index) in gameCategory"
          :key="index"
          :class="['item', selectCategoryIndex == index ? 'active' : '']"
          @click="onChangeCategory(name, index)"
        >
          {{ name }}
        </div>
      </div>
      <div class="games-list">
        <router-link
          v-for="(game, i) in gameList"
          :key="i"
          class="game"
          :to="{
            path: '/games/detail',
            query: { id: game.Code },
          }"
        >
          <img class="games-img" :src="game.SmallCoverUrl" />
          <!-- <div class="tag self">{{ $t('Games.SelfOperated') }}</div> -->
          <div class="like-wrap pc-v-hide">
            <div class="like">{{ game.likeNum }}</div>
            <div class="unlike">{{ game.dislikeNum }}</div>
          </div>
          <div class="bottom">
            <div class="grade-wrap">
              <div>
                <div class="num">{{ game.score }}</div>
                <div>{{ $t('Games.Rating') }}</div>
              </div>
              <div class="m-v-hide">
                <img class="like" src="@/assets/page-games/like.png" />
                <div>{{ game.likeNum }}</div>
              </div>
              <div class="m-v-hide">
                <img class="unlike" src="@/assets/page-games/unlike.png" />
                <div>{{ game.dislikeNum }}</div>
              </div>
            </div>
            <div class="btn">{{ $t('Games.PlayNow') }}</div>
          </div>
        </router-link>
      </div>
      <div class="pagination-wrap" v-if="Math.ceil(total / pageSize) > 1">
        <Pagination :total="total" :size="pageSize" :current="pageNo" @jump="onJump"></Pagination>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/layouts/Page.vue'
import Pagination from '@/components/Pagination.vue'
import Slider from '@/components/Slider.vue'
import SliderItem from '@/components/SliderItem.vue'
import emitter from '@/common/js/emitter'
import { isSupportedChain } from '@/web3/wallet'
import ERC20CommentDefi from '@/web3/ERC20CommentDefi'
import { showNumK } from '@/models/game.model'

export default {
  components: {
    Page,
    Slider,
    SliderItem,
    Pagination,
  },
  data() {
    return {
      commentContract: [],
      dropdownShow: false,
      bannerSliderIndex: 0,

      gameType: [
        this.$t('Games.Games'),
        this.$t('Games.SelfOperatedGames'),
        this.$t('Games.ThirdPartyGames'),
      ],
      currentGameType: 0,
      showGameType: this.$t('Games.Games'),

      gameCategory: [
        this.$t('Games.All'),
        this.$t('Games.ShootingGames'),
        this.$t('Games.TDG'),
        this.$t('Games.RoundRobinGames'),
        this.$t('Games.FosterGames'),
        this.$t('Games.RolePlayingGames'),
        this.$t('Games.ActionGames'),
        this.$t('Games.CardGames'),
        this.$t('Games.CompetitiveGames'),
      ],
      gameList: [],
      currentGameCategory: this.$t('Games.All'),
      pageNo: 1,
      pageSize: 12,
      selectCategoryIndex: 0,
      total: 0,
    }
  },
  methods: {
    onBannerSlide({ index }) {
      this.bannerSliderIndex = index
    },
    onBannerChange(index) {
      this.$bannerSlider.jump(index)
    },
    onJump(index) {
      this.pageNo = index
      this.getGameList()
    },
    showType() {
      this.dropdownShow = !this.dropdownShow
    },
    selectType(index) {
      this.showGameType = this.gameType[index]
      this.currentGameType = index
      this.dropdownShow = !this.dropdownShow
      if (index == 2) {
        this.gameList = []
      } else {
        this.getGameList()
      }
    },
    onChangeCategory(category, index) {
      this.currentGameCategory = category
      this.selectCategoryIndex = index
      if (this.currentGameType == 2) {
        return
      }
      if (
        category == this.$t('Games.All') ||
        category == this.$t('Games.ShootingGames') ||
        category == this.$t('Games.TDG')
      ) {
        this.getGameList(category)
      } else {
        this.gameList = []
      }
    },
    async getGameList(category) {
      this.gameList = []
      const url = (process.env.VUE_APP_GAME_URL || '') + '/gamecenter/list'
      const params = {
        category: category == 'All' ? 'all' : category,
        pageSize: this.pageSize || 12,
        pageNo: this.pageNo || 1,
      }
      await this.$axios
        .get(url, { params })
        .then((res) => {
          if (res.code != 200) return
          const data = res.data || {}

          // 分页
          const pageInfo = data.page || {}
          this.total = pageInfo.Total || 0
          this.pageSize = pageInfo.Limit || 12
          this.pageNo = pageInfo.CurrentPage || 1

          // 列表
          this.gameList = data.list || []
          this.gameList.forEach((elem, index) => {
            this.commentContract[index] = new ERC20CommentDefi(elem.CommentContractAddr)
          })
        })
        .catch((err) => {
          console.error(err)
        })
      if (this.gameList.length == 0) {
        return
      }
      for (let i = 0; i < this.gameList.length; i++) {
        const info = await this.commentContract[i].getParameters()
        this.gameList[i].score = info.averageScore.toFixed(1)
        this.gameList[i].likeNum = showNumK(info.praiseNum)
        this.gameList[i].dislikeNum = showNumK(info.badNum)
      }
    },
    async init({ accounts, chainId } = {}) {
      if (!accounts || !accounts.length) {
        return
      }
      if (!isSupportedChain(chainId)) {
        alert('Please change your chain provider to the Binance Smart Chain (or testnet)')
        return
      }
      this.getGameList()
    },
  },
  mounted() {
    this.$bannerSlider = this.$refs.bannerSlider
    emitter.on('connect-wallet', this.init)
    this.$root.connectWallet()
  },
  unmounted() {
    emitter.off('connect-wallet', this.init)
  },
}
</script>

<style lang="scss">
.games {
  .games-header {
    display: flex;
    align-items: center;
    height: 2.9rem;
    padding: 0 4rem;
    font-size: 0.9rem;
    vertical-align: middle;
    border-bottom: 0.05rem solid #5a5c66;
    img {
      width: 1rem;
      height: 1rem;
      padding-bottom: 0.4rem;
      margin: 0.4rem 0.65rem 0 0;
      vertical-align: middle;
    }
    .dropdown-wrap {
      position: relative;
    }
    .header-show {
      width: 10rem;
      margin: 0.75rem;
      cursor: pointer;
      &::after {
        content: '';
        display: inline-block;
        margin-left: 0.55rem;
        vertical-align: middle;
        border-left: 0.3rem solid transparent;
        border-top: 0.3rem solid #fff;
        border-right: 0.3rem solid transparent;
      }
    }
    .header-dropdown {
      position: absolute;
      top: -1.2rem;
      z-index: 2;
      border-radius: 0.3rem;
      background-color: rgba(0, 0, 0, 0.46);
      div {
        width: 10rem;
        margin: 0.75rem;
        text-align: left;
        cursor: pointer;
        &:first-child {
          &::after {
            content: '';
            display: inline-block;
            margin-left: 0.55rem;
            vertical-align: middle;
            border-left: 0.3rem solid transparent;
            border-top: 0.3rem solid #fff;
            border-right: 0.3rem solid transparent;
          }
        }
      }
    }
    @media (max-width: 768.89px) {
      padding: 0 1.3rem;
      font-size: 0.6rem;
      border-bottom: 0 none;
      img {
        width: 0.5rem;
        height: 0.5rem;
        margin: 0.4rem 0.35rem 0 0;
      }
      .header-show {
        width: 4.2rem;
        margin: 0.5rem;
        cursor: pointer;
        &::after {
          content: '';
          margin-left: 0.3rem;
          border-width: 0.2rem;
        }
      }
      .header-dropdown {
        top: -0.8rem;
        div {
          width: 4.2rem;
          margin: 0.5rem;
          text-align: left;
          cursor: pointer;
          &:first-child {
            &::after {
              margin-left: 0.3rem;
              border-width: 0.2rem;
            }
          }
        }
      }
    }
  }
  .games-wrap {
    height: 100%;
    padding: 0 4rem;
    background: url('~@/assets/page-defi/content-bg.png') 0 0 no-repeat;
    @media (max-width: 768.89px) {
      padding: 0 1.3rem;
    }
  }
  .banners {
    position: relative;
    max-width: 51.45rem;
    margin: 1.35rem 0 1.6rem;
    &:hover {
      .banner-left,
      .banner-right {
        opacity: 1;
      }
    }
    .banner-slider {
      width: 100%;
      max-width: 51.45rem;
      height: 19.1rem;
      border-radius: 1.45rem;
    }
    .banner {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .dots {
      position: absolute;
      z-index: 5;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      .dot {
        display: inline-block;
        margin: 0 0.25rem;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.5rem;
        background-color: #fff;
        transition: width 0.1s;
        &.active {
          width: 3rem;
          background: linear-gradient(90deg, #0078ff, #ae00ff);
        }
      }
    }
    @media (max-width: 768.89px) {
      .banner-slider {
        width: 100%;
        height: 63vw;
      }
      .banner {
        width: 100%;
      }
      .dots {
        bottom: 1rem;
        .dot {
          margin: 0 0.125rem;
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 0.25rem;
          &.active {
            width: 1.5rem;
          }
        }
      }
    }
  }
  .games-sub-header {
    display: flex;
    align-items: center;
    max-width: 51.45rem;
    font-size: 0.8rem;
    border-bottom: 0.05rem solid #62636a;
    overflow-x: auto;
    .item {
      margin: 0 2rem 0.65rem 0;
      border-bottom: 0.15rem solid transparent;
      white-space: nowrap;
      cursor: pointer;
      &.active {
        border-bottom-color: #efb35c;
      }
    }
    @media (max-width: 768.89px) {
      font-size: 0.6rem;
      font-weight: bold;
      &::-webkit-scrollbar {
        display: none;
      }
      .item {
        margin: 0 1rem 0 0;
        padding-bottom: 0.2rem;
        border-bottom: 0.1rem solid transparent;
      }
    }
  }
  .games-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 51.45rem;
    margin-top: 1.6rem;
    .game {
      position: relative;
      min-width: 11.2rem;
      max-width: 23%;
      margin: 0 1.5% 1.45rem 0;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 0.8rem;
      overflow: hidden;
      &::before {
        content: '';
        display: block;
        padding-top: 59%;
      }
      .games-img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
      }
      .tag {
        position: absolute;
        left: 0;
        top: 0.7rem;
        height: 1.3rem;
        padding: 0 0.7rem;
        line-height: 1.3rem;
        font-size: 0.6rem;
        text-align: center;
        border-top-right-radius: 0.65rem;
        border-bottom-right-radius: 0.65rem;
        background: linear-gradient(90deg, #4b00e3, #ec8532);
        // &::before {
        //   content: '';
        //   display: inline-block;
        //   width: 1rem;
        //   height: 1rem;
        //   margin-right: 0.5rem;
        //   vertical-align: middle;
        //   background: url('~@/assets/page-games/header-avatar.png') center center no-repeat;
        //   background-size: 100%;
        // }
        &.third {
          background: #22b65d;
          // &::before {
          //   content: '';
          //   background-image: url('~@/assets/page-games/header-third.png');
          // }
        }
      }
      .bottom {
        position: absolute;
        display: flex;
        align-items: center;
        height: 2.2rem;
        padding: 0 0.5rem;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
        background-color: rgba(0, 0, 0, 0.7);
      }
      .grade-wrap {
        flex: 1;
        display: flex;
        font-size: 0.6rem;
        div {
          text-align: center;
          margin-right: 0.1rem;
        }
        .num {
          line-height: 1rem;
          text-align: center;
          font-size: 1rem;
          font-weight: bold;
        }
        .like {
          margin-bottom: 0.1rem;
          width: 0.8rem;
        }
        .unlike {
          margin-top: 0.1rem;
          width: 0.8rem;
        }
      }
      .btn {
        width: 4rem;
        height: 1.5rem;
        line-height: 1.5rem;
        text-align: center;
        font-size: 0.6rem;
        border-radius: 0.75rem;
        background-color: #6441d3;
        cursor: pointer;
        border: 0;
        color: #fff;
      }
    }
    @media (max-width: 768.89px) {
      min-height: initial;
      margin-top: 0.9rem;
      .game {
        min-width: 48%;
        max-width: 48%;
        width: 48%;
        margin: 0 2% 0.6rem 0;
        border-radius: 0.4rem;
        .tag {
          bottom: 0.65rem;
          height: 0.8rem;
          padding: 0 0.5rem;
          line-height: 0.8rem;
          font-size: 0.4rem;
          border-top-right-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
          &::before {
            content: '';
            display: inline-block;
            width: 0.6rem;
            height: 0.6rem;
            margin-right: 0.2rem;
          }
        }
        .like-wrap {
          position: absolute;
          top: 0.7rem;
          right: 0.5rem;
          font-size: 0.4rem;
          .like,
          .unlike {
            &::before {
              content: '';
              display: inline-block;
              width: 0.6rem;
              height: 0.6rem;
              margin-right: 0.2rem;
              background: url('~@/assets/page-games/like.png');
              background-size: 100%;
            }
          }
          .unlike {
            &::before {
              vertical-align: middle;
              background-image: url('~@/assets/page-games/unlike.png');
            }
          }
        }
        .bottom {
          height: 1.6rem;
          padding: 0 0.2rem;
          border-bottom-left-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }
        .grade-wrap {
          font-size: 0.4rem;
          div {
            margin-right: 0.02rem;
          }
          .num {
            line-height: 0.6rem;
            font-size: 0.6rem;
          }
        }
        .btn {
          right: 0.3rem;
          bottom: 0.55rem;
          width: 3.3rem;
          height: 1rem;
          line-height: 1rem;
          font-size: 0.5rem;
          border-radius: 0.5rem;
        }
      }
    }
  }

  .pagination-wrap {
    margin: 2rem 0 0 0;
    text-align: right;
    @media (max-width: 768.89px) {
      margin: 0.5rem 0;
      text-align: center;
    }
  }
}
</style>
