<template>
  <div class="stars">
    <i
      :class="['star', order <= level ? 'light' : '']"
      v-for="order in [1, 2, 3, 4, 5]"
      :key="order"
      @click="onToggle(order)"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    level: {
      type: Number,
      value: 0,
    },
  },
  data() {
    return {}
  },
  methods: {
    onToggle(order) {
      this.$emit('change', order)
    },
  },
}
</script>

<style lang="scss" scoped>
.stars {
  display: inline-block;
  .star {
    display: inline-block;
    width: 17px;
    height: 17px;
    vertical-align: middle;
    background: url('~@/assets/page-games/star-undo.png') center center no-repeat;
    background-size: 100%;
    &.light {
      background-image: url('~@/assets/page-games/star.png');
    }
  }
}
</style>
