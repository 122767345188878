<template>
  <div class="game-collections">
    <section class="banners">
      <Slider
        class="banner-slider"
        ref="bannerSlider"
        :autoplay="true"
        :distance="6000"
        @slide-start="onBannerSlide"
      >
        <SliderItem v-for="banner in banners" :key="banner">
          <video
            v-if="banner.type === 'video'"
            :ref="banner.name"
            class="inner"
            :src="banner.img"
            loop
            autoplay
          ></video>
          <img v-else class="inner" :src="banner.img" />
        </SliderItem>
      </Slider>
      <div class="dots">
        <div
          v-for="(banner, index) in banners"
          :key="banner"
          :class="['dot', bannerIndex == index ? 'active' : '']"
          @click="onBannerChange(index)"
        ></div>
      </div>
    </section>
    <section class="main-wrap2">
      <div class="condition">
        <GameTypeSelector
          class="game-type-selector"
          v-model="gameType"
          @change="onChangeFilter"
        ></GameTypeSelector>
        <ProviderSelector
          class="provider-selector"
          v-model="provider"
          @change="onChangeFilter"
        ></ProviderSelector>
      </div>
      <div class="layout-grid-list">
        <ProjectCard
          class="project-card"
          v-for="item in list"
          :key="item.name"
          :project="item"
          @click="onProject(item)"
        ></ProjectCard>
      </div>
      <div class="pagination-wrap" v-if="total > pageSize">
        <Pagination :total="total" :size="pageSize" :current="pageNo" @jump="onJump"></Pagination>
      </div>
    </section>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import SliderItem from '@/components/SliderItem.vue'
import GameTypeSelector from '@/views/Market2/components/GameTypeSelector.vue'
import ProviderSelector from '@/views/Market2/components/ProviderSelector.vue'
import ProjectCard from '@/views/Market2/components/ProjectCard.vue'
import Pagination from '@/components/Pagination.vue'
import initTriggerMixin from '@/mixins/init-trigger'

const banners = [
  {
    url: '',
    img: require('@/assets/page-game2/page-collections/banner-pump.mp4'),
    name: 'pump',
    type: 'video',
  },
  {
    url: '',
    img: require('@/assets/page-game2/page-collections/banner-1.mp4'),
    name: 'usdc',
    type: 'video',
  },
]

export default {
  components: {
    Slider,
    SliderItem,
    GameTypeSelector,
    ProviderSelector,
    ProjectCard,
    Pagination,
  },
  data() {
    return {
      gameType: '',
      provider: '',

      total: 0,
      pageSize: 12,
      pageNo: 1,

      list: [],

      banners,
      bannerIndex: 0,
    }
  },
  mixins: [initTriggerMixin()],
  methods: {
    onBannerSlide({ index = 0 }) {
      this.bannerIndex = index

      const banner = this.banners[index]
      if (!banner) return

      if (banner.type === 'video') {
        this.$refs[banner.name].play()
      }
    },
    onBannerChange(index) {
      this.$refs.bannerSlider.jump(index)
    },

    onChangeFilter() {
      this.pageNo = 1
      this._getList()
    },
    onJump(pageNo) {
      this.pageNo = pageNo
      this._getList()
    },

    onProject(project) {
      this.$router.push({
        path: '/game2/project',
        query: {
          id: project.ID,
          code: project.Symbol,
        },
      })
    },

    async _getList() {
      const url = `${process.env.VUE_APP_API_FBOX2}/web/collections/get_list`
      const params = {
        category: this.gameType || '',
        type: this.provider || '',
        isGame: 1,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      const res = await this.$axios.get(url, { params })
      if (res.code != 200) {
        this.total = 0
        this.list = []
        console.warn(res.msg)
        return
      }

      const data = res.data || {}
      this.total = data.Total || 0
      this.list = data.List || []
    },
    async init() {
      this.gameType = ''
      this.provider = ''

      this.total = 0
      this.pageNo = 1
      await this._getList()
    },
  },
  mounted() {
    this.onBannerSlide({ index: 0 })
  },
}
</script>

<style lang="scss" scoped>
@import '@/common/css/variable.scss';

.game-collections {
  .banners {
    position: relative;
    height: 24.1rem;
    background-color: #13141d;
    overflow: hidden;
    @media (max-width: 768.89px) {
      height: 8.0333rem;
    }
    .banner-slider {
      width: 100%;
      height: 100%;
    }
    .inner {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      transform: translateX(-50%);
    }

    .dots {
      position: absolute;
      z-index: 5;
      bottom: 0.7rem;
      left: 50%;
      transform: translateX(-50%);
      line-height: 1;
      white-space: nowrap;
      @media (max-width: 768.89px) {
        bottom: 0.35rem;
      }
      .dot {
        display: inline-block;
        margin: 0 0.25rem;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.5rem;
        background-color: #fff;
        transition: width 0.1s;
        cursor: pointer;
        @media (max-width: 768.89px) {
          margin: 0 0.15rem;
          width: 0.25rem;
          height: 0.25rem;
        }
        &.active {
          width: 1.5rem;
          background-image: linear-gradient(90deg, #00aaff 0%, #df01ff 100%);
          @media (max-width: 768.89px) {
            width: 0.75rem;
          }
        }
      }
    }
  }

  // .game-title {
  //   line-height: 1;
  //   font-size: 1.5rem;
  //   font-weight: 500;
  //   @media (max-width: 768.89px) {
  //     margin-top: 1.2rem;
  //     margin-bottom: 0.75rem;
  //     padding-bottom: 0.75rem;
  //   }
  // }

  .condition {
    margin: 2.5rem 0 0;
    display: flex;
    @media (max-width: 768.89px) {
      margin: 0.5rem 0 1rem;
      display: block;
    }
    .game-type-selector {
      flex: 1;
      @media (max-width: 768.89px) {
        margin-bottom: 0.2rem;
      }
    }
  }
  .layout-grid-list {
    margin-top: 0.7rem;
    border-top: 0.05rem solid $color-border;
    padding-top: 1.5rem;
    @media (max-width: 768.89px) {
      padding-top: 1rem;
    }
  }
}
</style>
