import Settings from '@/views/Settings'
import Account from '@/views/Settings/Account'
import Game from '@/views/Settings/Game'
import Notice from '@/views/Settings/Notice'

const routes = [
  {
    path: '/settings',
    component: Settings,
    redirect: (to) => `${to.path.replace(/\/$/, '')}/account`,
    children: [
      {
        path: 'account',
        component: Account,
      },
      {
        path: 'game',
        component: Game,
      },
      {
        path: 'notice',
        component: Notice,
      },
    ],
  },
]

export default routes
