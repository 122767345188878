<template>
  <div class="project-card">
    <div class="header">
      <div class="name">
        <div
          :class="['image', project.VerifyStatus ? 'cert' : '']"
          :style="{ backgroundImage: `url('${project.LogoUrl}')` }"
        ></div>
        <span class="text">{{ project.Name }}</span>
      </div>
      <div class="like">
        <div class="like-inner" @click.stop="onFav">
          <i :class="['icon', isFav ? 'self' : '']"></i>
          <span class="count">{{ favCount }}</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div
        :class="['image', false ? 'new' : '']"
        :style="{ backgroundImage: `url('${project.CoverUrl}')` }"
      ></div>
      <div class="info">
        <div class="score">
          <template v-if="project.IsGame">
            <Stars class="stars" :score="project.CommentScore"></Stars>
            <span class="text">{{ parseFloat(project.CommentScore).toFixed(1) }}</span>
          </template>
          <img class="chain" :src="getChainLogo(project.Chain)" />
        </div>
        <div class="online" v-if="project.IsGame">
          <span class="label">{{ $t('Market2.ActivePlayers') }}:</span>
          <span class="num">{{ project.PlayerCount }}</span>
        </div>
      </div>

      <div class="explain">
        <p class="text">
          {{ project.Brief }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from '@/modules/Stars.vue'
import { favProject, unfavProject } from '@/models/market2.model'
import { getChainLogo } from '../common/helper'

export default {
  components: {
    Stars,
  },
  props: {
    project: Object,
  },
  data() {
    const project = this.project || {}

    return {
      isFav: !!project.IsFavorite,
      favCount: project.FavCount || 0,
    }
  },
  watch: {
    project(project = {}) {
      this.isFav = !!project.IsFavorite
      this.favCount = project.FavCount || 0
    },
  },
  methods: {
    getChainLogo,

    async onFav() {
      if (!this.$root.token) {
        this.$root.connectWallet()
        return
      }

      if (this.isFav) {
        const res = await unfavProject(this.project.ID)
        if (res.code != 200) {
          this.$modal.toast(res.msg)
          return
        }
        this.isFav = false
        this.favCount -= 1
      } else {
        const res = await favProject(this.project.ID)
        if (res.code != 200) {
          this.$modal.toast(res.msg)
          return
        }
        this.isFav = true
        this.favCount += 1
      }
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
@import '@/common/css/variable.scss';

.project-card {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 0.1rem solid $color-purple;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: 768.89px) {
    border-width: 0.05rem;
    border-radius: 0.3rem;
  }
  .header {
    display: flex;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 0.9rem;
    @media (max-width: 768.89px) {
      height: 1.6rem;
      line-height: 1.6rem;
      font-size: 0.7rem;
    }
    .name {
      flex: 1;
      display: flex;
      align-items: center;
      height: inherit;
      .image {
        position: relative;
        margin: 0 0.4rem 0 0.5rem;
        width: 1.8em;
        height: 1.8em;
        border-radius: 50%;
        background: $color-placeholder center center / contain no-repeat;
        @media (max-width: 768.89px) {
          margin: 0 0.2rem 0 0.3rem;
          width: 1rem;
          height: 1rem;
        }
        &.cert::before {
          content: '';
          position: absolute;
          top: -0.1em;
          right: -0.1em;
          display: block;
          width: 0.6em;
          height: 0.6em;
          background: url('~@/assets/common/icon-cert.png') center center / 100% no-repeat,
            linear-gradient(0, #fff, #fff) center center / 70% 70% no-repeat;
        }
      }
      .text {
        flex: 1;
        width: 0;
        padding-right: 0.6rem;
        @include ellipsis(1);
      }
    }
    .like {
      min-width: 27%;
      font-size: 0.9em;
      .like-inner {
        position: relative;
        padding-right: 0.15rem;
        height: 85%;
        line-height: 2.1rem;
        text-align: center;
        @media (max-width: 768.89px) {
          line-height: 1.4rem;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0.1rem;
          height: 120%;
          background-color: $color-purple;
          transform: rotate(-30deg);
          transform-origin: left bottom;
          @media (max-width: 768.89px) {
            width: 0.05rem;
          }
        }
        &::after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 0.1rem;
          background-color: $color-purple;
          @media (max-width: 768.89px) {
            height: 0.05rem;
          }
        }
      }
      .icon {
        display: inline-block;
        margin: 0 0.2em 0 -0.3em;
        width: 1.15em;
        height: 1.15em;
        background: url('~@/assets/page-market2/comp-card/icon-like-0.png') left center / contain
          no-repeat;
        vertical-align: middle;
        overflow: hidden;
        &.self {
          background-image: url('~@/assets/page-market2/comp-card/icon-like-1.png');
        }
      }
      .count {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .main {
    flex: 1;
    position: relative;
    padding: 0 0.65rem;
    @media (max-width: 768.89px) {
      padding: 0 0.3rem;
    }
    .image {
      position: relative;
      padding-top: 90%;
      background: center center / contain no-repeat;
      overflow: hidden;
      &.new::before {
        content: 'NEW';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 3.6em 1.5em 0;
        line-height: 1.2;
        font-size: 0.6rem;
        font-weight: 500;
        color: $color-primary;
        background-color: $color-purple;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    .info {
      position: relative;
      padding: 0.4rem 0;
      .score {
        margin-bottom: 0.1rem;
        line-height: 1.2;
        font-size: 1.2em;
        font-weight: 500;
        color: $color-gray;
        overflow: hidden;
        @media (max-width: 768.89px) {
          font-size: 1em;
        }
        .stars {
          vertical-align: middle;
          @media (max-width: 768.89px) {
            vertical-align: 0.3rem;
          }
        }
        .text {
          margin-left: 0.5rem;
          vertical-align: -0.2rem;
          @media (max-width: 768.89px) {
            margin-left: 0.2rem;
            vertical-align: middle;
          }
        }
        .chain {
          float: right;
          margin-top: 0.2rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: $color-placeholder6;
          overflow: hidden;
          @media (max-width: 768.89px) {
            margin-top: 0.1rem;
            width: 0.8rem;
            height: 0.8rem;
          }
        }
      }
      .online {
        font-size: 0.8rem;
        @media (max-width: 768.89px) {
          line-height: 1.2;
          font-size: 0.65rem;
        }
        .label {
          color: $color-secondary;
          vertical-align: 0.15rem;
          @media (max-width: 768.89px) {
            vertical-align: 0.1rem;
          }
        }
        .num {
          margin-left: 0.4rem;
          font-size: 1.4em;
          font-weight: 500;
          @media (max-width: 768.89px) {
            margin-left: 0.3rem;
          }
        }
      }
    }
    .explain {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      line-height: 1.5;
      font-size: 0.7rem;
      color: $color-white;
      background-color: rgba($color-black, 0.5);
      @media (max-width: 768.89px) {
        line-height: 1.3;
        font-size: 0.55rem;
      }
      .text {
        height: 70%;
        margin: 1rem 0;
        padding: 0 1.2rem;
        overflow-y: auto;
        @media (max-width: 768.89px) {
          height: 90%;
          margin: 0.5rem 0;
          padding: 0 0.5rem;
        }
      }
    }
  }
  &:hover {
    .main {
      .explain {
        display: block;
      }
    }
  }
}
</style>
