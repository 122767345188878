import { TOKEN_CLV, TOKEN_FBX, NFT_FANTASYBOX } from '@/web3/contract.constant'

export const STAKING = {
  fbx3000_lv1_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Machine-gun NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Machine-gun.png',
    duration: 60,
    amount: '3000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    tag: 'new',
    defi: '0x79339F63565001f468B79B3d8D53c6C1bc31337E',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx5000_lv2_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Frozen NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Frozen.png',
    duration: 60,
    amount: '5000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    tag: 'new',
    defi: '0xAE8F71Fd07E128572fdb04442AE08744917f3a2D',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx6000_lv3_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Fire NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Fire.png',
    duration: 60,
    amount: '6000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    tag: 'new',
    defi: '0x3dAC7A39911df413d8efa8026fC5406f876dCc33',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx7000_lv4_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Laser NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Laser.png',
    duration: 60,
    amount: '7000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    tag: 'new',
    defi: '0x5EE99475b981BCbA20F1be83295B74Ebb4c2431e',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx8000_lv5_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Railgun NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Railgun.png',
    duration: 60,
    amount: '8000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    tag: 'new',
    defi: '0x7033db37ee39Ab77d3569BdD38eE7Ad317bb50Fd',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx10000_lv6_2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Missile NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Missile.png',
    duration: 60,
    amount: '10000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    tag: 'new',
    defi: '0x0D48dc17e760560C4d59bCDceA2DcA2FA443D4e5',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx3000_lv1: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Machine-gun NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Machine-gun.png',
    duration: 30,
    amount: '3000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    defi: '0xA6FAbc7b5F93CFEc547Cd95dF6da0Ac8D72370af',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx5000_lv2: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Frozen NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Frozen.png',
    duration: 30,
    amount: '5000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    defi: '0x71cA799A0A2991cDaf5D5B84bb4753F079A0A6bf',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx6000_lv3: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Fire NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Fire.png',
    duration: 30,
    amount: '6000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    defi: '0x4A1AFfebd525D090ffaE38485ca6423361E88668',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx7000_lv4: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Laser NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Laser.png',
    duration: 30,
    amount: '7000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    defi: '0xEda2c1532347E640DE7362cc4CF46baDad37e9d4',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx8000_lv5: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Railgun NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Railgun.png',
    duration: 30,
    amount: '8000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    defi: '0x0bd6dab2f8d17CE38B15102Ce4AD7facA26352da',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  fbx10000_lv6: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Missile NFT',
    image: 'https://static.forthbox.io/image/nft/ham-fantasy/Missile.png',
    duration: 30,
    amount: '10000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    defi: '0x4a6b0314b707e6fE2B6199D122a7397B2dAdc202',
    texts: ['DeFi.Turret_Text1', 'DeFi.Turret_Text2', 'DeFi.Turret_Text3', 'DeFi.Turret_Text4'],
  },
  clover1500: {
    name: 'ForthBox-Clover',
    coin: 'CLV',
    earn: 'Rare Fighter NFT',
    image: 'https://static.forthbox.io/image/aircraft/R.png',
    duration: 60,
    amount: '1500.00',
    totalNFTNum: 100,
    token: TOKEN_CLV,
    visible: true,
    defi: '0x43f11e8eC4F72E6F6b250E1648270b07a1ab5e28',
    texts: [
      'DeFi.Fighter_Text1',
      'DeFi.Fighter_Text2',
      'DeFi.Fighter_Text3',
      'DeFi.Fighter_Text4',
      'DeFi.Fighter_Text5',
    ],
  },
  fbx3000: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Normal Fighter NFT',
    image: 'https://static.forthbox.io/image/aircraft/N.png',
    duration: 30,
    amount: '3000.00',
    totalNFTNum: 1000,
    token: TOKEN_FBX,
    visible: true,
    defi: '0xDA420B819c9157B95C1BE47916Fe395464329614',
    texts: [
      'DeFi.Fighter_Text1',
      'DeFi.Fighter_Text2',
      'DeFi.Fighter_Text3',
      'DeFi.Fighter_Text4',
      'DeFi.Fighter_Text5',
    ],
  },
  fbx5000: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'Rare Fighter NFT',
    image: 'https://static.forthbox.io/image/aircraft/R.png',
    duration: 30,
    amount: '5000.00',
    totalNFTNum: 500,
    token: TOKEN_FBX,
    visible: true,
    defi: '0x57b75c537a951aCaA4204555204C02230C1B65a1',
    texts: [
      'DeFi.Fighter_Text1',
      'DeFi.Fighter_Text2',
      'DeFi.Fighter_Text3',
      'DeFi.Fighter_Text4',
      'DeFi.Fighter_Text5',
    ],
  },
  fbx8000: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'SR Fighter NFT',
    image: 'https://static.forthbox.io/image/aircraft/SR.png',
    duration: 30,
    amount: '8000.00',
    totalNFTNum: 300,
    token: TOKEN_FBX,
    visible: true,
    defi: '0x67ee1Db4F2879B8d7C92F3f5551667d29c130b7B',
    texts: [
      'DeFi.Fighter_Text1',
      'DeFi.Fighter_Text2',
      'DeFi.Fighter_Text3',
      'DeFi.Fighter_Text4',
      'DeFi.Fighter_Text5',
    ],
  },
  fbx10000: {
    name: 'ForthBox-FBX',
    coin: 'FBX',
    earn: 'SSR Fighter NFT',
    image: 'https://static.forthbox.io/image/aircraft/SSR.png',
    duration: 30,
    amount: '10000.00',
    totalNFTNum: 100,
    token: TOKEN_FBX,
    visible: true,
    defi: '0x4933A020A697f94387f25230AaBdb7FA40082599',
    texts: [
      'DeFi.Fighter_Text1',
      'DeFi.Fighter_Text2',
      'DeFi.Fighter_Text3',
      'DeFi.Fighter_Text4',
      'DeFi.Fighter_Text5',
    ],
  },
  forth100: {
    name: 'ForthBox-FORTH',
    coin: 'FORTH',
    earn: 'SSR Fighter NFT',
    image: 'https://static.forthbox.io/image/aircraft/SSR.png',
    duration: 180,
    amount: '100.00',
    totalNFTNum: 100,
    visible: true,
    token: '0xC979C4B8193C22eA0FD685395E504f407d3aB887',
    defi: '0x908849c3f0c777418C0686318DB5E277BDA3bfD9',
    texts: [
      'DeFi.Fighter_Text1',
      'DeFi.Fighter_Text2',
      'DeFi.Fighter_Text3',
      'DeFi.Fighter_Text4',
      'DeFi.Fighter_Text5',
    ],
  },
}

export const LPFARM = {
  fbxusdt: {
    type: ['fbx', 'usdt'],
    images: [require('@/assets/page-defi/forthbox.png'), require('@/assets/page-defi/usdt.png')],
    pair: 'FBX-USDT',
    name: 'DeFi.PancakeFBXUSDTLPs',
    earn: 'FBX',
    lockUpTime: 'DeFi.Flexible',
    time: lastTime(180, 1639454186000), // 有效期：180天；开始时间：Dec-14-2021 11:56:26 AM +UTC
    isEnd: lastTime(180, 1639454186000) <= 0,
    amount: '5400,000',
    amountNum: 900000,
    token: '0x9f07679ea7011da476ed03968558742e518bca38',
    defi: '0x75523248d4119204af918075fbb03c6F0fa6054b',
  },
  forthusdt: {
    type: ['forth', 'usdt'],
    images: [require('@/assets/page-defi/forth.png'), require('@/assets/page-defi/usdt.png')],
    pair: 'FORTH-USDT',
    name: 'DeFi.PancakeFORTHUSDTLPs',
    earn: 'FBX',
    lockUpTime: 'DeFi.Flexible',
    time: 0,
    isEnd: true,
    amount: '3600,000',
    amountNum: 600000,
    token: '0x32fe33c9A503937464473c537f8adafa5708d1D6',
    defi: '0x3cCcCA2Dd94896Df49d7EC0082F1525edb6c84C5',
  },
}

function lastTime(duration, startTime) {
  const lastDay = duration - (Date.now() - startTime) / 24 / 60 / 60 / 1000
  return lastDay > 0 ? Math.floor(lastDay) : 0
}

export const NFT = {
  bull_stellar_s: {
    name: 'Fantasy Box',
    coin: 'Fantasy Box',
    earn: 'Planet MetaBull <S> NFT',
    type: 1,
    icon: require('@/assets/bull/bull-planet-head-s.png'),
    image: require('@/assets/bull/bull-planet-body-s.png'),
    imageStyle: 'max-width: 170%; max-height: 170%; transform: translate(-18%, -25%);',
    duration: 60,
    amount: '15',
    totalNFTNum: 1000,
    token: NFT_FANTASYBOX,
    visible: true,
    defi: '0xBc8069B90A86fD74ae1036E5f40A410e14C460dC',
    texts: ['DeFi.BULL_Text1', 'DeFi.BULL_Text2', 'DeFi.BULL_Text3', 'DeFi.BULL_Text4'],
  },
  bull_stellar_m: {
    name: 'Fantasy Box',
    coin: 'Fantasy Box',
    earn: 'Planet MetaBull <M> NFT',
    type: 2,
    icon: require('@/assets/bull/bull-planet-head-m.png'),
    image: require('@/assets/bull/bull-planet-body-m.png'),
    imageStyle: 'max-width: 145%; max-height: 145%; transform: translate(-14%, -18%);',
    duration: 60,
    amount: '20',
    totalNFTNum: 600,
    token: NFT_FANTASYBOX,
    visible: true,
    defi: '0x9fe7F284CeE6451CB4C49f37010E06CFB346767a',
    texts: ['DeFi.BULL_Text1', 'DeFi.BULL_Text2', 'DeFi.BULL_Text3', 'DeFi.BULL_Text4'],
  },
  bull_stellar_l: {
    name: 'Fantasy Box',
    coin: 'Fantasy Box',
    earn: 'Planet MetaBull <L> NFT',
    type: 3,
    icon: require('@/assets/bull/bull-planet-head-l.png'),
    image: require('@/assets/bull/bull-planet-body-l.png'),
    imageStyle: 'max-width: 125%; max-height: 125%; transform: translate(-5%, -13%);',
    duration: 60,
    amount: '30',
    totalNFTNum: 400,
    token: NFT_FANTASYBOX,
    visible: true,
    defi: '0x2b2f10Fd6A93021B8b883fD0fD3B676b8739057a',
    texts: ['DeFi.BULL_Text1', 'DeFi.BULL_Text2', 'DeFi.BULL_Text3', 'DeFi.BULL_Text4'],
  },
  bull_stellar_xl: {
    name: 'Fantasy Box',
    coin: 'Fantasy Box',
    earn: 'Planet MetaBull <XL> NFT',
    type: 4,
    icon: require('@/assets/bull/bull-planet-head-xl.png'),
    image: require('@/assets/bull/bull-planet-body-xl.png'),
    duration: 60,
    amount: '45',
    totalNFTNum: 200,
    token: NFT_FANTASYBOX,
    visible: true,
    defi: '0x99e2cd3271BA65B2Ff28eA742235061E5Acbe764',
    texts: ['DeFi.BULL_Text1', 'DeFi.BULL_Text2', 'DeFi.BULL_Text3', 'DeFi.BULL_Text4'],
  },
  bull_stellar_all: {
    name: 'Fantasy Box',
    coin: 'Fantasy Box',
    earn: 'Planet MetaBull NFT',
    type: 5,
    icon: require('@/assets/bull/bull-planet-body-all.png'),
    image: require('@/assets/bull/bull-planet-body-all.png'),
    duration: 60,
    amount: '100',
    totalNFTNum: 100,
    token: NFT_FANTASYBOX,
    visible: true,
    defi: '0x78c396a6D96B888bCCb1b184b9fbAfa12398260D',
    texts: ['DeFi.BULL_Text1', 'DeFi.BULL_Text2', 'DeFi.BULL_Text3', 'DeFi.BULL_Text4'],
  },
}

// Bull-彗星系列
// https://static.forthbox.io/image/nft/metabull/bull-full/comet/comet-s.png
// https://static.forthbox.io/image/nft/metabull/bull-full/comet/comet-m.png
// https://static.forthbox.io/image/nft/metabull/bull-full/comet/comet-l.png
// https://static.forthbox.io/image/nft/metabull/bull-full/comet/comet-xl.png

// Bull-行星系列
// https://static.forthbox.io/image/nft/metabull/bull-full/planet/planet-s.png
// https://static.forthbox.io/image/nft/metabull/bull-full/planet/planet-m.png
// https://static.forthbox.io/image/nft/metabull/bull-full/planet/planet-l.png
// https://static.forthbox.io/image/nft/metabull/bull-full/planet/planet-xl.png

// Bull-恒星系列
// https://static.forthbox.io/image/nft/metabull/bull-full/stellar/stellar-s.png
// https://static.forthbox.io/image/nft/metabull/bull-full/stellar/stellar-m.png
// https://static.forthbox.io/image/nft/metabull/bull-full/stellar/stellar-l.png
// https://static.forthbox.io/image/nft/metabull/bull-full/stellar/stellar-xl.png

// Bull-星系系列
// https://static.forthbox.io/image/nft/metabull/bull-full/galaxy/galaxy-s.png
// https://static.forthbox.io/image/nft/metabull/bull-full/galaxy/galaxy-m.png
// https://static.forthbox.io/image/nft/metabull/bull-full/galaxy/galaxy-l.png
// https://static.forthbox.io/image/nft/metabull/bull-full/galaxy/galaxy-xl.png
