<template>
  <Page class="socialfi">
    <img class="comming-soon" src="@/assets/comp-page/coming-soon-4.png" />
  </Page>
</template>

<script>
import Page from '@/layouts/Page.vue'

export default {
  components: {
    Page,
  },
  mounted() {
    this.$root.connectWallet()
  },
  unmounted() {},
}
</script>

<style lang="scss">
.socialfi {
  .comming-soon {
    width: 100%;
  }
}
</style>
