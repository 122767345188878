<template>
  <Page class="games-detail">
    <div class="detail-wrap">
      <div class="detail-header m-v-hide">
        <div class="name">{{ gameDetail.Name }}</div>
        <div><img src="@/assets/page-games/header-avatar.png" /></div>
      </div>
      <div class="detail-banner">
        <img :src="gameDetail.BigCoverUrl" @click="jumpGameWeb()" />
        <div class="content">
          <div class="left-wrap m-v-hide">
            <div>
              <div class="grade">{{ gameDetail.score }}</div>
              <div>{{ $t('Games.Rating') }}</div>
            </div>
            <div class="icon like">{{ gameDetail.likeNum }} {{ $t('Games.Like') }}</div>
            <div class="icon unlike">{{ gameDetail.dislikeNum }} {{ $t('Games.Dislike') }}</div>
          </div>
          <div class="right-wrap">
            <!-- <div class="btn present" @click="visible = true">每日奖励</div> -->
            <a class="btn play" :href="gameDetail.PlayUrl" target="_blank">{{
              $t('Games.PlayNow')
            }}</a>
          </div>
        </div>
      </div>
      <div class="detail-middle-wrap pc-v-hide">
        <div class="layout-flex-auto">
          <div class="name">{{ gameDetail.name }}</div>
          <div class="like-wrap">
            <div class="like">{{ gameDetail.likeNum }} {{ $t('Games.Like') }}</div>
            <div class="unlike">{{ gameDetail.dislikeNum }} {{ $t('Games.Dislike') }}</div>
          </div>
        </div>
        <div>
          <div class="num">{{ gameDetail.score }}</div>
          <div>{{ $t('Games.Rating') }}</div>
        </div>
      </div>
      <div class="profit-wrap">
        <div class="left-wrap">
          <div class="profit-block profit-amount">
            <div class="title">
              {{ $t('Games.TotalAmountOfCommentPool') }}
              <div class="tip-wrap">
                <img src="@/assets/page-games/tip-logo.png" />
                <div class="tip-content">
                  <div>{{ $t('Games.TheCurrentContract') }}</div>
                  <div>{{ $t('Games.DuringTheTest') }}</div>
                </div>
              </div>
            </div>
            <div class="text logo-1">{{ ownInfo.totalRewardYet }}</div>
          </div>
          <div class="profit-block">
            <div class="title">{{ $t('Games.ToatalHashrateOfComment') }}</div>
            <div class="text logo-2">{{ ownInfo.totalSupply }}</div>
          </div>
        </div>
        <div class="right-wrap profit-block">
          <div class="right-block">
            <div class="title">{{ $t('Games.MyHashrate') }}</div>
            <div class="text logo-2">{{ ownInfo.balances }}</div>
          </div>
          <div class="right-block">
            <div class="title">{{ $t('Games.MyRevenue') }}</div>
            <div class="text logo-1">{{ ownInfo.earned }}</div>
          </div>
          <div class="right-block profit-btn-wrap">
            <Button
              class="profit-btn"
              :handler="getReward"
              :loading="getRewardLoading"
              :disabled="disableGetReward"
            >
              {{ $t('Games.Withdraw') }}
            </Button>
          </div>
        </div>
      </div>
      <div class="detail-introduce">
        <div class="intro-title">
          <div class="layout-flex-auto">{{ $t('Games.Introduction') }}</div>
          <div class="player">{{ gameDetail.OnlineUsers }} {{ $t('Games.PlayersPlaying') }}</div>
        </div>
        <div class="intro-text">
          <!-- {{ gameDetail.Description }} -->
          <div v-html="gameDetail.Description"></div>
        </div>
      </div>
      <div class="detial-bottom">
        <div class="comment-wrap">
          <div class="title">
            <div class="layout-flex-auto">{{ $t('Games.Comments') }}</div>
            <div class="anchor" @click="visibleIntro = true">{{ $t('Games.Guide') }}</div>
          </div>
          <div class="comment-main">
            <div class="comment-block" v-for="(comment, index) in commentList" :key="index">
              <div class="block-left">
                <div class="name">{{ showUserAddr(comment.UserAddr) }}</div>
                <div>{{ comment.Content }}</div>
              </div>
              <div class="block-right">
                <div class="date">{{ showDateTime(comment.CommentTime * 1000) }}</div>
                <div class="grade-wrap">
                  <div class="star-wrap">
                    <Stars :level="comment.Score"></Stars>
                  </div>
                  <div>
                    <img src="@/assets/page-games/like.png" v-if="comment.IsPraise" />
                    <img src="@/assets/page-games/unlike.png" v-if="!comment.IsPraise" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-wrap" v-if="Math.ceil(total / pageSize) > 1">
            <Pagination
              :total="total"
              :size="pageSize"
              :current="pageNo"
              @jump="onJump"
            ></Pagination>
          </div>
          <div class="comment-bottom">
            <textarea
              :placeholder="$t('Games.PleaseEnterTheContents')"
              v-model="submitComment.content"
            ></textarea>
            <div class="comment-bottom-select">
              <div class="layout-flex-auto">{{ $t('Games.PleaseChooseYourHam') }}</div>
              <div class="right-wrap">
                <img src="@/assets/page-games/comment-logo.png" />
                <div class="select-wrap" :class="toggleSelectHam ? 'active' : ''">
                  <div class="select-detail" @click="toggleSelectHam = !toggleSelectHam">
                    <div>Level:{{ selectedHam.level }}</div>
                    <div class="id">#{{ selectedHam.token }}</div>
                    <div class="select-btn"></div>
                  </div>
                  <div class="option-wrap">
                    <div class="current-select" @click="toggleSelectHam = !toggleSelectHam">
                      <div>Level:{{ selectedHam.level }}</div>
                      <div class="id">#{{ selectedHam.token }}</div>
                      <div class="select-btn"></div>
                    </div>
                    <ul class="option-ul">
                      <li
                        class="option-li"
                        :class="submitHamNfts[i] === true ? 'not-allowed' : ''"
                        v-for="(ham, i) in hamNfts"
                        :key="i"
                        @click="setSelectedHam(ham, i)"
                      >
                        <div>Level:{{ ham.level }}</div>
                        <div class="id">#{{ ham.token }}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tips">{{ $t('Games.ScoreAndCommentTheGameToGetFbxRewards') }}</div>
            <div class="send-block">
              <div class="layout-flex-auto">
                <Stars :level="submitComment.score" @change="changeScore"></Stars>
              </div>
              <div class="btn-wrap">
                <div
                  class="like"
                  @click="switchLike(true)"
                  :class="submitComment.isPraise ? 'done' : ''"
                >
                  {{ $t('Games.Like') }}
                </div>
                <div
                  class="unlike"
                  @click="switchLike(false)"
                  :class="!submitComment.isPraise ? 'done' : ''"
                >
                  {{ $t('Games.Dislike') }}
                </div>
                <Button
                  class="btn"
                  :disabled="false"
                  :loading="submitLoading"
                  :handler="onSubmitComment"
                >
                  {{ $t('Games.Send') }}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div class="rank-wrap">
          <div class="title">{{ $t('Games.Rankings') }}</div>
          <div class="rank-main">
            <div class="rank-block" v-for="(info, index) in rankList" :key="index">
              <div class="num" v-if="index + 1 <= rankList.length">
                <img
                  v-if="index <= 2"
                  :src="require(`@/assets/page-games/order-${index + 1}.png`)"
                />
                <span v-else>{{ index + 1 }}</span>
              </div>
              <div class="name">{{ showUserAddr(info.UserAddr) }}</div>
              <div class="point">Point: {{ info.Score }}</div>
            </div>
          </div>
          <div class="rank-block rank-last">
            <!-- <div class="num">{{ rankList.length }}</div> -->
            <!-- <div class="name">{{ lastRank.UserAddr }}</div> -->
            <div class="name rank">
              <img src="@/assets/page-games/rank.png" />
              <span>{{ $t('Games.TimeOfRankingsStatistics') }}</span>
              <span class="time">{{ showDateTime(0) }}</span>
            </div>
            <!-- <div class="point">Point: {{ lastRank.Score }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <Dialog class="dialog dialog-introduction" :visible="visibleIntro" @hide="onHideIntro">
      <main class="main">
        <header class="header">
          <div class="close-wrap">
            <div class="title">{{ $t('Games.Guide') }}</div>
            <a class="close" @click="onHideIntro"></a>
          </div>
        </header>
        <div class="content-block">
          <div>
            {{ $t('Games.ToEncourageUsersParticipating') }}
          </div>
          <div>{{ $t('Games.TheRevenueFormula') }}</div>
        </div>
        <div class="content-block">
          <table>
            <thead>
              <th>{{ $t('Games.ITEM') }}</th>
              <th>{{ $t('Games.CONTENT') }}</th>
              <th>{{ $t('Games.DESCRIPTION') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t('Games.PrizeForComment') }}</td>
                <td>{{ $t('Games.FBXTokens') }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t('Games.Formula') }}</td>
                <td>{{ $t('Games.IndividualHashrate') }}</td>
                <td>
                  <div>{{ $t('Games.MStandsFor') }}</div>
                  <div>{{ $t('Games.NStandsFor') }}</div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('Games.ContractValidity') }}</td>
                <td>{{ $t('Games.1Month') }}</td>
                <td>{{ $t('Games.ExpiresAfter1Month') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="content-block">
          {{ $t('Games.IfThereAreDeliberatelyMaliciousComments') }}
        </div>
      </main>
    </Dialog>
  </Page>
</template>

<script>
import Page from '@/layouts/Page.vue'
import Pagination from '@/components/Pagination.vue'
import Button from '@/components/Button.vue'
import Stars from './Stars.vue'
import Dialog from '@/components/Dialog.vue'
import emitter from '@/common/js/emitter'
import { isSupportedChain } from '@/web3/wallet'
import ERC20CommentDefi from '@/web3/ERC20CommentDefi'
import { showNumK } from '@/models/game.model'
import { initNftList } from '@/models/ham.model'

export default {
  components: {
    Page,
    Dialog,
    Pagination,
    Button,
    Stars,
  },
  data() {
    return {
      visible: false,
      visibleIntro: false,
      commentContract: null,
      gameCode: '',
      gameDetail: {
        Name: '',
        score: 0.0,
        likeNum: 0,
        dislikeNum: 0,
        BigCoverUrl: '',
        Description: '',
        PlayUrl: '',
        OnlineUsers: 0,
      },
      ownInfo: {
        totalSupply: 0,
        balances: 0,
        earned: 0.0,
        totalRewardYet: 0,
      },
      pageNo: 1,
      pageSize: 10,
      total: 1,
      rankList: [],
      // lastRank: [],
      commentList: [],
      disableSubmit: false,
      submitLoading: false,
      getRewardLoading: false,
      disableGetReward: false,
      submitComment: {
        isPraise: true,
        content: '',
        score: 5,
      },
      hamNfts: [],
      selectedHam: {
        token: 0,
        level: 0,
      },
      submitHamNfts: [],
      commentContractAddr: '',
      toggleSelectHam: false,
    }
  },
  methods: {
    onHide() {
      this.visible = false
    },
    onHideIntro() {
      this.visibleIntro = false
    },
    switchLike(status) {
      if (status == this.submitComment.isPraise) {
        return
      }
      this.submitComment.isPraise = !this.submitComment.isPraise
    },
    changeScore(score) {
      this.submitComment.score = score
    },
    showUserAddr(addr) {
      return addr.slice(0, 4) + '...' + addr.slice(-4)
    },
    showDateTime(timestamp) {
      let date
      if (timestamp == 0) {
        date = new Date()
      } else {
        date = new Date(timestamp)
      }
      const Y = date.getFullYear() + '-'
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      if (timestamp == 0) {
        return Y + M + D + h + m
      }
      const s = ':' + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      return Y + M + D + h + m + s
    },
    scoreArr(score) {
      return [...Array(score)].map((_) => 0)
    },
    noScoreArr(score) {
      return [...Array(5 - score)].map((_) => 0)
    },
    onJump(index) {
      this.pageNo = index
      this.getCommentList()
    },
    jumpGameWeb() {
      window.open(this.gameDetail.OfficailUrl, '_blank')
    },
    init({ accounts, chainId } = {}) {
      if (!accounts || !accounts.length) {
        return
      }
      if (!isSupportedChain(chainId)) {
        alert('Please change your chain provider to the Binance Smart Chain (or testnet)')
        return
      }
      this.UserAddr = accounts[0]
      this.commentContract = new ERC20CommentDefi(this.commentContractAddr)
      this.appendGameDetail()
      this.getHamNftList()
    },
    setSelectedHam(ham, index) {
      if (this.submitHamNfts[index] === false) {
        this.selectedHam = ham
      } else {
        alert('Comment have already been posted with this ham')
      }
      this.toggleSelectHam = false
    },
    //获取留言列表
    async getCommentList() {
      const url = (process.env.VUE_APP_GAME_URL || '') + '/gamecenter/comments'
      const params = {
        gameCode: this.gameCode,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      await this.$axios
        .get(url, { params })
        .then((res) => {
          if (res.code != 200) return
          const data = res.data || {}

          // 分页
          const pageInfo = data.page || {}
          this.total = pageInfo.Total || 0
          this.pageSize = pageInfo.Limit || 12
          this.pageNo = pageInfo.CurrentPage || 1

          // 列表
          this.commentList = data.list || []
        })
        .catch((err) => {
          console.error(err)
        })
    },
    //获取排行榜
    async getRankList() {
      const url = (process.env.VUE_APP_GAME_URL || '') + '/gamecenter/user_rank_list'
      const params = {
        code: this.gameCode,
        n: 100,
      }
      await this.$axios
        .get(url, { params })
        .then((res) => {
          if (res.code != 200) return
          const data = res.data || {}

          this.rankList = data || {}
          // this.lastRank = this.rankList[this.rankList.length - 1]
        })
        .catch((err) => {
          console.error(err)
        })
    },
    //发布留言
    async onSubmitComment() {
      if (this.disableSubmit) {
        alert('Only leave a message once')
        return
      }
      if (this.submitComment.content.length == 0) {
        alert('Comment can not be empty')
        return
      }
      if (this.selectedHam.token == 0) {
        alert('please select a ham nft')
        return
      }
      this.disableSubmit = true
      this.submitLoading = true
      try {
        await this.commentContract.writeComment(
          this.submitComment.content,
          this.submitComment.score,
          this.submitComment.isPraise,
          this.selectedHam.token
        )
        this.commentList.unshift({
          Content: this.submitComment.content,
          Score: this.submitComment.score,
          IsPraise: this.submitComment.isPraise,
          UserAddr: this.UserAddr,
          CommentTime: 0,
        })
        this.submitComment = {
          isPraise: true,
          content: '',
          score: 5,
        }
        this.appendGameDetail()
      } catch (err) {
        console.log(err)
        // let error = err.message.replace('Internal JSON-RPC error.\n', '')
        // error = JSON.parse(error)
        // alert(error.message)
        this.disableSubmit = false
      }
      this.submitLoading = false
    },
    //获取游戏详情
    async getGameDetail() {
      const url = (process.env.VUE_APP_GAME_URL || '') + '/gamecenter/show'
      const params = {
        code: this.gameCode,
      }
      await this.$axios
        .get(url, { params })
        .then((res) => {
          if (res.code != 200) return
          const data = res.data || {}
          this.gameDetail = data || {}
          this.commentContractAddr = data.CommentContractAddr
        })
        .catch((err) => {
          console.error(err)
        })
    },
    //获取合约游戏详情
    async appendGameDetail() {
      const info = await this.commentContract.getParameters()
      console.log(info)
      this.gameDetail.score = info.averageScore.toFixed(1)
      this.gameDetail.likeNum = showNumK(info.praiseNum)
      this.gameDetail.dislikeNum = showNumK(info.badNum)
      if (info.balances > 0) {
        this.disableSubmit = true
      }
      if (info.earned == 0) {
        this.disableGetReward = true
      }
      // const totalReward = await this.commentContract.totalRewardYet()
      this.ownInfo = {
        totalSupply: Math.floor(info.totalSupply * 1000) / 1000,
        balances: Math.floor(info.balances * 1000) / 1000,
        earned: Math.floor(info.earned * 1000) / 1000,
        totalRewardYet: 1000000,
      }
    },
    //获取奖励
    async getReward() {
      this.getRewardLoading = true
      try {
        await this.commentContract.getReward()
        this.appendGameDetail()
        this.disableGetReward = true
      } catch (err) {
        console.log(err)
      }
      this.getRewardLoading = false
    },
    //获取ham列表
    getHamNftList() {
      return Promise.all([
        window.cNFTFun.tokenOfOwner(),
        window.cFBX_NFTFun.getOwnerStakeTokenIDs(),
      ])
        .then(([nftTokens = [], stakedNftTokens = []]) => {
          const tokens = [...nftTokens, ...stakedNftTokens]
          const count = tokens.length
          if (!count) {
            this.hamNfts = []
            return
          }
          Promise.all([initNftList(nftTokens, false), initNftList(stakedNftTokens, true)]).then(
            ([nfts, stakedNfts]) => {
              this.hamNfts = [...nfts, ...stakedNfts].map((nft) => ({
                token: nft.token,
                // level: nft.level.lv + '-' + nft.level.exp,
                level: nft.level.lv,
              }))

              if (this.hamNfts.length) {
                this.selectedHam = this.hamNfts[0]
              }

              this.getSubmitHamNft(tokens)
            }
          )
        })
        .catch(() => {
          this.hamNfts = []
        })
    },
    async getSubmitHamNft(nfts) {
      this.submitHamNfts = await this.commentContract.bCommentHamIds(nfts)
      console.log(this.submitHamNfts)
    },
  },
  async mounted() {
    if (!this.$route.query.id) {
      alert('Error. Url parameter requires id.')
      return
    }
    this.gameCode = this.$route.query.id
    await this.getGameDetail()
    this.getRankList()
    this.getCommentList()
    this.$bannerSlider = this.$refs.bannerSlider
    emitter.on('connect-wallet', this.init)
    this.$root.connectWallet()
  },
  unmounted() {
    emitter.off('connect-wallet', this.init)
  },
}
</script>

<style lang="scss">
.not-allowed {
  cursor: not-allowed !important;
}
.pagination-wrap {
  margin: 0.5rem 0;
  text-align: right;
  @media (max-width: 768.89px) {
    margin: 0.5rem 0;
    text-align: center;
  }
}
.games-detail {
  .detail-wrap {
    padding: 0 4rem;
    background: url('~@/assets/page-defi/content-bg.png') 0 0 no-repeat;
    @media (max-width: 768.89px) {
      padding: 0 1.3rem;
    }
  }
  .detail-header {
    display: flex;
    align-items: center;
    max-width: 51.45rem;
    height: 2.9rem;
    margin: 1.8rem auto 1.45rem;
    .name {
      flex: 1;
      font-size: 1.3rem;
      font-weight: bold;
    }
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  .detail-banner {
    position: relative;
    max-width: 51.45rem;
    margin: 0 auto;
    border-radius: 1.45rem;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      cursor: pointer;
    }
    .content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4.5rem;
      padding: 0 2.15rem;
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      background-color: rgba(0, 0, 0, 0.44);
      @media (max-width: 768.89px) {
        height: 3rem;
        padding: 0 1rem;
        justify-content: right;
        background-color: transparent;
      }
    }
    .left-wrap {
      flex: 1;
      display: flex;
      align-items: center;
      .grade {
        font-size: 1.95rem;
        font-weight: bold;
      }
      .like {
        margin: 0 1.8rem 0.5rem 3rem;
      }
    }
    .icon {
      &::before {
        content: '';
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.85rem;
        background: url('~@/assets/page-games/like.png') center center no-repeat;
        background-size: 100%;
      }
    }
    .unlike {
      &.icon {
        &::before {
          vertical-align: middle;
          background: url('~@/assets/page-games/unlike.png') center center no-repeat;
          background-size: 100%;
        }
      }
    }
    .right-wrap {
      display: flex;
      .btn {
        width: 6.8rem;
        height: 2.3rem;
        line-height: 2.3rem;
        text-align: center;
        border-radius: 1.15rem;
        cursor: pointer;
        box-sizing: border-box;
      }
      .present {
        margin-right: 0.95rem;
        &::before {
          content: '';
          display: inline-block;
          width: 1.05rem;
          height: 1.05rem;
          margin-right: 0.35rem;
          vertical-align: middle;
          background: url('~@/assets/page-games/present.png') center center no-repeat;
          background-size: 100%;
        }
        border: 0.1rem solid #fff;
      }
      .play {
        color: #fff;
        background-color: #6441d3;
      }
      @media (max-width: 768.89px) {
        .btn {
          width: 4.2rem;
          height: 1.2rem;
          line-height: 1.2rem;
          font-size: 0.6rem;
          border-radius: 0.6rem;
        }
        .present {
          margin-right: 0.5rem;
          &::before {
            width: 0.6rem;
            height: 0.6rem;
            margin-right: 0.15rem;
          }
          border: 0.05rem solid #fff;
        }
      }
    }
  }
  .profit-wrap {
    display: flex;
    max-width: 51.45rem;
    box-sizing: border-box;
    padding: 0.4rem 0.4rem 0.4rem 1.8rem;
    background-color: #353644;
    border-radius: 0.8rem;
    margin: 0.5rem auto;
    .title {
      margin-bottom: 0.5rem;
      font-size: 0.8rem;
    }
    .text {
      font-size: 1rem;
      font-weight: bold;
    }
    .logo-1,
    .logo-2 {
      &::before {
        content: '';
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.2rem;
        vertical-align: middle;
        background: url('~@/assets/page-games/detail-logo-1.png') center center no-repeat;
        background-size: 100%;
      }
    }
    .logo-2 {
      &::before {
        background-image: url('~@/assets/page-games/detail-logo-2.png');
      }
    }
    .profit-block {
      flex: 1;
      padding: 0.8rem 0;
    }
    .profit-amount {
      .title {
        display: flex;
        img {
          width: 0.85rem;
          height: 0.85rem;
          margin-left: 0.3rem;
          cursor: pointer;
        }
      }
      .tip-wrap {
        position: relative;
        .tip-content {
          display: none;
          position: absolute;
          bottom: 1.5rem;
          padding: 0.5rem;
          width: 10rem;
          font-size: 0.6rem;
          background-color: rgba(0, 0, 0, 0.44);
          border-radius: 0.4rem;
          @media (max-width: 768.89px) {
            width: 6rem;
            padding: 0.3rem;
            font-size: 0.4rem;
            border-radius: 0.2rem;
          }
        }
        &:hover {
          .tip-content {
            display: block;
          }
        }
      }
    }
    .left-wrap {
      display: flex;
      flex: 1;
    }
    .right-wrap {
      display: flex;
      flex: 1.5;
      align-items: center;
      padding: 0.8rem;
      background-color: #262738;
      border-radius: 0.55rem;
      .right-block {
        flex: 1;
      }
      .profit-btn-wrap {
        text-align: right;
      }
      .profit-btn {
        display: inline-block;
        width: 6.2rem;
        height: 2.44rem;
        line-height: 2.44rem;
        text-align: center;
        font-size: 0.9rem;
        color: #353644;
        border-radius: 1.22rem;
        background-color: #eebf2f;
      }
    }
    @media (max-width: 768.89px) {
      display: block;
      padding: 0.5rem 0.4rem;
      border-radius: 0.4rem;
      .title {
        margin-bottom: 0.3rem;
        font-size: 0.55rem;
      }
      .text {
        font-size: 0.75rem;
      }
      .logo-1,
      .logo-2 {
        &::before {
          content: '';
          width: 0.8rem;
          height: 0.8rem;
          margin-right: 0.1rem;
        }
      }
      .right-wrap {
        padding: 0.4rem;
        border-radius: 0.3rem;
        .profit-btn {
          width: 3.5rem;
          height: 1.2rem;
          line-height: 1.2rem;
          font-size: 0.6rem;
          border-radius: 0.6rem;
        }
      }
    }
  }
  .detail-middle-wrap {
    display: flex;
    align-items: center;
    padding: 0.7rem 0.5rem 0;
    font-size: 0.6rem;
    .name {
      line-height: 1.6rem;
      font-size: 0.7rem;
      font-weight: bold;
    }
    .num {
      line-height: 1.6rem;
      font-size: 1.3rem;
      font-weight: bold;
    }
    .like-wrap {
      display: flex;
      .like,
      .unlike {
        margin-right: 0.5rem;
        &::before {
          content: '';
          display: inline-block;
          width: 0.6rem;
          height: 0.6rem;
          margin-right: 0.3rem;
          background: url('~@/assets/page-games/like.png') center center no-repeat;
          background-size: 100%;
        }
      }
      .unlike {
        &::before {
          vertical-align: middle;
          background-image: url('~@/assets/page-games/unlike.png');
        }
      }
    }
  }
  .detail-introduce {
    max-width: 51.45rem;
    box-sizing: border-box;
    margin: 0.65rem auto;
    padding: 1rem 1.8rem 2.1rem;
    font-size: 0.7rem;
    background-color: #353644;
    border-radius: 0.8rem;
    .intro-title {
      display: flex;
      align-items: center;
      padding-bottom: 0.95rem;
      font-size: 0.9rem;
      font-weight: bold;
      border-bottom: 0.05rem solid #5f6175;
      .player {
        font-size: 0.6rem;
        &::before {
          content: '';
          display: inline-block;
          width: 0.55rem;
          height: 0.55rem;
          margin-right: 0.55rem;
          border-radius: 0.55rem;
          background-color: #28fb64;
        }
      }
    }
    .intro-text {
      margin-top: 0.9rem;
      line-height: 1.2rem;
    }
    @media (max-width: 768.89px) {
      padding: 1rem 0.7rem 1rem;
      font-size: 0.6rem;
      border-radius: 0.4rem;
      .intro-title {
        padding-bottom: 0.5rem;
        font-size: 0.7rem;
        .player {
          &::before {
            content: '';
            width: 0.3rem;
            height: 0.3rem;
            margin-right: 0.3rem;
            border-radius: 0.3rem;
          }
        }
      }
      .intro-text {
        margin-top: 0.6rem;
        line-height: 0.8rem;
      }
    }
  }
  .detial-bottom {
    display: flex;
    max-width: 51.45rem;
    box-sizing: border-box;
    margin: auto;
    margin-bottom: 6rem;
    .comment-wrap,
    .rank-wrap {
      padding: 1.4rem 1.2rem 0;
      background-color: #353644;
      border-radius: 0.8rem;
      .title {
        display: flex;
        margin-bottom: 0.65rem;
        line-height: 2rem;
        font-size: 0.9rem;
        font-weight: bold;
        .anchor {
          &::after {
            content: '';
            display: inline-block;
            width: 1.1rem;
            height: 1.1rem;
            margin-left: 0.2rem;
            vertical-align: middle;
            background: url('~@/assets/page-games/comment-tip.png') no-repeat;
            background-size: 100%;
          }
          font-size: 0.7rem;
          color: #c7c7c7;
          cursor: pointer;
          @media (max-width: 768.89px) {
            &::after {
              width: 0.6rem;
              height: 0.6rem;
              margin-left: 0.2rem;
            }
            font-size: 0.5rem;
          }
        }
      }
    }
    .comment-wrap {
      flex: 1.5;
      margin-right: 0.8rem;
      width: 5rem;
      .comment-main {
        max-height: 15rem;
        margin-bottom: 1rem;
        padding-right: 0.3rem;
        overflow-y: auto;
      }
      .comment-block {
        display: flex;
        margin-bottom: 0.95rem;
        padding-bottom: 0.8rem;
        font-size: 0.8rem;
        border-bottom: 0.05rem solid #5f6175;
      }
      .block-left {
        flex: 1;
        .name {
          margin-bottom: 0.7rem;
          font-weight: bold;
        }
      }
      .block-right {
        width: 7.5rem;
        .date {
          margin: 0.2rem 0 0.8rem;
          font-size: 0.6rem;
          color: #93939f;
          text-align: right;
        }
        .grade-wrap {
          display: flex;
          justify-content: right;
          img {
            width: 0.85rem;
            height: 0.85rem;
          }
          .star-wrap {
            margin-right: 1rem;
          }
        }
      }
      .comment-bottom {
        padding: 0.6rem 0.95rem;
        margin-bottom: 1rem;
        font-size: 0.7rem;
        background-color: #2f2f36;
        border-radius: 1rem;
        textarea {
          display: block;
          width: 100%;
          height: 4rem;
          color: #6b6d83;
          border: 0 none;
          background-color: #2f2f36;
          &:focus {
            outline: 0 none;
          }
        }
        .comment-bottom-select {
          display: flex;
          margin-top: 0.4rem;
          padding-top: 0.7rem;
          color: #c7c7c7;
          border-top: #5f6175 solid 0.05rem;
          .right-wrap {
            display: flex;
            color: #8184a5;
            img {
              width: 1.1rem;
              height: 1.1rem;
              margin-right: 0.6rem;
              @media (max-width: 768.89px) {
                width: 0.8rem;
                height: 0.8rem;
                margin-right: 0.3rem;
              }
            }
            .select-wrap {
              position: relative;
              padding-right: 0.9rem;
              .current-select,
              .select-detail {
                display: flex;
                cursor: pointer;
              }
              .option-wrap {
                display: none;
                position: absolute;
                top: 0;
                padding: 0 0.9rem;
                border-radius: 0.3rem;
                background-color: #1d1d22;
                opacity: 0.85;
                .current-select {
                  height: 2rem;
                  line-height: 2rem;
                  border-bottom: 0.05rem solid #353644;
                  cursor: pointer;
                  .select-btn {
                    margin-top: 0.6rem;
                    transform: rotate(180deg);
                  }
                }
                .option-ul {
                  max-height: 6rem;
                  overflow-y: scroll;
                }
                .option-li {
                  display: flex;
                  height: 2rem;
                  line-height: 2rem;
                  cursor: pointer;
                }
              }
              .id {
                margin: 0 0.6rem;
              }
              .select-btn {
                height: 0;
                margin-top: 0.3rem;
                border: 0.2rem solid transparent;
                border-top-color: #fff;
                cursor: pointer;
              }
              &.active {
                margin-top: -0.55rem;
                .select-detail {
                  opacity: 0;
                  cursor: pointer;
                }
                .option-wrap {
                  display: block;
                }
              }
            }
          }
        }
        .tips {
          margin: 0.4rem 0 0.6rem;
          color: #c7c7c7;
        }
        .send-block {
          display: flex;
          align-items: center;
          img {
            width: 0.85rem;
            height: 0.85rem;
            cursor: pointer;
          }
          .btn-wrap {
            display: flex;
            align-items: center;
          }
          .like,
          .unlike {
            color: #c7c7c7;
            cursor: pointer;
            &::before {
              content: '';
              display: inline-block;
              width: 0.85rem;
              height: 0.85rem;
              margin-right: 0.35rem;
              background: url('~@/assets/page-games/like-undo.png') center center no-repeat;
              background-size: 100%;
            }
          }
          .unlike {
            margin: 0 0.8rem;
            &::before {
              margin-top: 0.2rem;
              vertical-align: middle;
              background-image: url('~@/assets/page-games/unlike-undo.png');
            }
          }
          .done {
            &.like {
              &::before {
                background-image: url('~@/assets/page-games/like.png');
              }
            }
            &.unlike {
              &::before {
                background-image: url('~@/assets/page-games/unlike.png');
              }
            }
          }
          .btn {
            width: 4rem;
            height: 1.8rem;
            line-height: 1.8rem;
            text-align: center;
            color: #353644;
            border-radius: 0.9rem;
            background-color: #eebf2f;
            cursor: pointer;
            border: 0;
          }
        }
      }
    }
    .rank-wrap {
      position: relative;
      flex: 1;
      overflow: hidden;
      .title {
        border-bottom: 0.05rem solid #5f6175;
      }
      .rank-main {
        height: 22rem;
        overflow-y: auto;
      }
      .rank-block {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        font-size: 0.7rem;
        .num {
          flex: 1.5;
          font-size: 1.5rem;
          font-weight: bold;
          color: #72748d;
          font-style: italic;
          img {
            width: 2.1rem;
          }
        }
        .name {
          flex: 2;
          text-align: center;
        }
        .point {
          flex: 4;
          text-align: right;
          margin-right: 0.5rem;
        }
      }
      .rank-last {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 1rem 0 0;
        padding: 0 1.2rem;
        line-height: 3.4rem;
        background-color: #1f202c;
        .rank {
          img {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.3rem;
            @media (max-width: 768.89px) {
              width: 0.65rem;
              height: 0.65rem;
            }
          }
          .time {
            margin-left: 0.3rem;
            color: #aeb1e2;
          }
        }
      }
    }
    @media (max-width: 768.89px) {
      flex-wrap: wrap;
      font-size: 0.6rem;
      .comment-wrap,
      .rank-wrap {
        padding: 0.9rem;
        border-radius: 0.4rem;
        .title {
          margin-bottom: 0.5rem;
          line-height: 1rem;
          font-size: 0.7rem;
        }
      }
      .comment-wrap {
        flex: initial;
        order: 2;
        width: 100%;
        margin: 0.65rem 0;
        .comment-main {
          max-height: 15rem;
          margin-bottom: 0.5rem;
          padding-right: 0.15rem;
        }
        .comment-block {
          margin-bottom: 0.6rem;
          padding-bottom: 0.4rem;
          font-size: 0.6rem;
        }
        .block-left {
          flex: 1;
          .name {
            margin-bottom: 0.6rem;
          }
        }
        .block-right {
          width: 7.5rem;
          .date {
            margin: 0.2rem 0 0.4rem;
          }
          .grade-wrap {
            img {
              width: 0.6rem;
              height: 0.6rem;
            }
            .star-wrap {
              margin-right: 0.5rem;
            }
          }
        }
        .comment-bottom {
          padding: 0.4rem 0.5rem;
          font-size: 0.6rem;
          border-radius: 0.5rem;
          textarea {
            height: 2rem;
          }
          .tips {
            margin: 0.2rem 0 0.4rem;
            padding-top: 0.1rem;
            border-top: 0 none;
          }
          .send-block {
            img {
              width: 0.6rem;
              height: 0.6rem;
            }
            .like,
            .unlike {
              &::before {
                width: 0.6rem;
                height: 0.6rem;
                margin-right: 0.2rem;
              }
            }
            .unlike {
              margin: 0 0.4rem;
              &::before {
                margin-top: 0.1rem;
              }
            }
            .btn {
              width: 2.2rem;
              height: 1rem;
              line-height: 1rem;
              border-radius: 0.5rem;
              border: 0;
            }
          }
        }
      }
      .rank-wrap {
        flex: initial;
        order: 1;
        width: 100%;
        .rank-main {
          height: 11rem;
          margin-bottom: 0.7rem;
        }
        .rank-block {
          display: flex;
          align-items: center;
          margin-top: 0.3rem;
          font-size: 0.6rem;
          .num {
            flex: 1.5;
            font-size: 0.9rem;
            img {
              width: 1.2rem;
            }
          }
        }
        .rank-last {
          padding: 0 0.6rem;
          line-height: 1.7rem;
        }
      }
    }
  }
  .dialog {
    .main {
      width: 37.25rem;
      padding: 0.95rem 1.85rem 2rem;
      background-color: #353644;
      border-radius: 1.2rem;
      text-align: left;
    }
    .header {
      display: flex;
      margin-bottom: 0;
      &::after {
        display: none;
      }
      .close-wrap {
        flex: 1;
        text-align: right;
      }
      .close {
        display: inline-block;
        width: 0.62rem;
        height: 0.62rem;
        background: url('~@/assets/home/dialog-close.png') 0 0 no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    @media (max-width: 768.89px) {
      .main {
        width: 15rem;
        padding: 0.4rem 0.9rem 1rem;
        border-radius: 0.6rem;
      }
      .header {
        .close {
          width: 0.35rem;
          height: 0.35rem;
        }
      }
    }
  }
  .dialog-introduction {
    .header {
      font-size: 0.9rem;
      .close-wrap {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        border-bottom: 0.05rem solid rgba(255, 255, 255, 0.42);
      }
      .title {
        flex: 1;
        text-align: left;
      }
    }
    font-size: 0.8rem;
    .content-block {
      margin-top: 1rem;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border: 0.05rem solid #fff;
      th,
      td {
        padding: 0.3rem;
        border: 0.05rem solid #fff;
      }
    }
    @media (max-width: 768.89px) {
      .header {
        font-size: 0.7rem;
        .close-wrap {
          padding-bottom: 0.5rem;
        }
      }
      .content-block {
        margin-top: 0.5rem;
        font-size: 0.4rem;
        table {
          th,
          td {
            font-size: 0.4rem;
            padding: 0.2rem;
          }
        }
      }
    }
  }
}
</style>
