export const BSCSCAN = 'https://bscscan.com'
export const BSCSCANTEST = 'https://testnet.bscscan.com'

export const ETHERSCAN = 'https://etherscan.io'

export const CHAIN_BSC_TEST = 97
export const CHAIN_BSC = 56
export const CHAIN_ETH = 1

// ================================================
// 代币
// ================================================

export const TOKEN_FBX = '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795'
// export const TOKEN_FBX = '0xB57Eb2C440002D5f53d89c379A313C0cA9601d8b' // 测试

export const TOKEN_USDC = '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
// export const TOKEN_USDC = '0xB57Eb2C440002D5f53d89c379A313C0cA9601d8b' // 测试

export const TOKEN_CLV = '0x09E889BB4D5b474f561db0491C38702F367A4e4d'
export const TOKEN_BNB = '0xB8c77482e45F1F44dE1745F52C74426C631bDD52'

// ================================================
// NFT
// ================================================

export const NFT_HAM = '0x1F599A0281d024bfeF7e198bDae78B49A6e87049'
// export const NFT_HAM = '0xE56f00d1240F23658C87aA5d539f6A17741F3ab8' // 测试

export const NFT_FANTASYBOX = '0x1d64E85a41a711a6aeF17792A660b3E69a7dA758'
// export const NFT_FANTASYBOX = '0xFCD819b38C6F7037B331F20081977e2b04162D5B' // 测试

export const NFT_METABULL = '0x95cbF549f2b03a7cbB8825c92645891165B41D7D'
// export const NFT_METABULL = '0x2dA3e7d6d2c1199b62B209AdAafa398002df3ef2' // 测试

export const NFT_TOWER = '0x52C2b76C30fB7D1581DdCfa20e07d1ae789FF912'
// export const NFT_TOWER = '0x69002D892E9207aDa1a7C56cadA6F72864097525' // 测试

// ================================================
// NFT质押
// ================================================

export const STAKE_HAM = '0x55F2856706872F69E8CfC00C2abDf2d4adf6aE50'

// ================================================
// 盲盒
// ================================================

export const BOX_FIGHTER = '0x9Bd1bf00c201738E596e655f50ABdA8888f0650B'
export const BOX_TOWER = '0x8D0ea624179B9D1d80f6c0E72e5a20971e8c22B0'

export const BOX_METABULL = '0x5C5402c8473ca5C471b1Df925867fA2dB59ff9FD'
// export const BOX_METABULL = '0xb504285c28CD42a1DEA2269f9f2B20e1C9285729' // 测试

// ================================================
// Market
// ================================================

export const MARKET =
  process.env.NODE_ENV === 'production'
    ? '0x2241b80561AD7fE4514608A4cf540bd0d043982C' // 正式
    : '0x91ef67B4d37161F7CAE39C90C1AA44B8d5Fe5Ac1' // 测试

// ================================================
// 运营促销活动
// ================================================

/** Metabull助力活动 */
export const PROMO_METABULL = '0xC6d4667adEA40801b6A7dA30C695BB5AeF510162'
// export const PROMO_METABULL = '0x148905f45eE2FAf7d7200058635d9f892Ce76ab3' // 测试
