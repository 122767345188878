import Game2 from '@/views/Game2'
import Collections from '@/views/Game2/Collections.vue'
import Project from '@/views/Game2/Project.vue'

const routes = [
  {
    path: '/game2',
    component: Game2,
    redirect: (to) => `${to.path.replace(/\/$/, '')}/collections`,
    children: [
      {
        path: 'collections',
        component: Collections,
      },
      {
        path: 'project',
        component: Project,
      },
    ],
  },
]

export default routes
