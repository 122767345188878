export default {
  USERNAME: [
    { type: 'required', message: 'username is required' },
    {
      type: 'between',
      params: { min: 6, max: 20 },
      message: "username's length is 6-20 characters",
    },
    {
      type: 'regexp',
      params: '^[A-Za-z][A-Za-z0-9_\\-.]{5,19}$',
      message: "username's format is invalid",
    },
  ],
  EMAIL: [{ type: 'required', message: 'email is required' }],
  PHONE: [{ type: 'required', message: 'phone is required' }],
  VERIFY_CODE: [{ type: 'required', message: 'verifyCode is required' }],
  PASSWORD: [{ type: 'required', message: 'password is required' }],
  PASSWORD_CONFIRM: [{ type: 'required', message: 'confirm password is required' }],
}
